import isFunction from "lodash/isFunction";
import { useContext } from "react";

import { Divider } from "components/Divider";
import { Button } from "design_system/Button";
import { ChevronLeft, Close } from "design_system/Icons";
import { FullPageLayoutContext } from "../context/FullPageLayout.context";

import { colorsV2 } from "constants/colors";
import { isAppPlatform } from "helpers/isAppPlatform";
import type {
  HeaderLayoutProps,
  MobilePageHeaderProps,
  PageHeaderProps,
} from "../types/PageHeaderLayout.types";

const MobilePageHeader = ({
  onBack,
  onClose,
  title,
}: MobilePageHeaderProps) => {
  const { hideDividers, isMobile, theme } = useContext(FullPageLayoutContext);
  return (
    <>
      <header className="flex items-center justify-between px-16 py-12">
        {isFunction(onBack) ? (
          <Button
            iconOnly
            size="md"
            Icon={ChevronLeft}
            iconFill={theme === "dark" ? colorsV2[theme].text[2] : undefined}
            variant="outlined"
            onClick={onBack}
          />
        ) : (
          <div className="left-16 p-20" />
        )}

        <h1 className="header-small">{title}</h1>

        {!isAppPlatform() && (
          <Button
            iconOnly
            size="md"
            Icon={Close}
            iconFill={theme === "dark" ? colorsV2[theme].text[2] : undefined}
            variant="outlined"
            onClick={onClose}
          />
        )}
      </header>
      {!isMobile && hideDividers === false && <Divider />}
    </>
  );
};

const PageHeader = ({ title, onClose }: PageHeaderProps) => {
  const { hideDividers, isMobile, theme } = useContext(FullPageLayoutContext);

  return (
    <>
      <header className="relative flex h-96 items-center pl-40 lg:pl-80">
        {title && <h1 className="header-large">{title}</h1>}

        {!isAppPlatform() && (
          <Button
            iconOnly
            size="lg"
            Icon={Close}
            iconFill={theme === "dark" ? colorsV2[theme].text[2] : undefined}
            classes="top-24 right-40 md:right-80"
            style={{
              position: "absolute",
            }}
            variant="outlined"
            onClick={onClose}
          />
        )}
      </header>
      {!isMobile && hideDividers === false && <Divider />}
    </>
  );
};

export const HeaderLayout = (props: HeaderLayoutProps) => {
  const { isMobile, ...restProps } = props;

  if (isMobile) {
    return <MobilePageHeader {...(restProps as MobilePageHeaderProps)} />;
  }
  return <PageHeader {...(restProps as PageHeaderProps)} />;
};
