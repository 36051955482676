import { useQuery } from "@tanstack/react-query";
import { variables } from "config/variables";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { useMerchantInfo } from "repositories/merchant/_hooks/use-merchant-info";

export const useInitializeLaunchDarkly = () => {
  const clientSideID = variables.launchDarkly.clientID;
  // biome-ignore lint/suspicious/noConsoleLog: <explanation>
  console.log("clientSideID", clientSideID);
  const { data: merchant } = useMerchantInfo();

  return useQuery(
    ["launchDarklyProvider", merchant],
    async () => {
      if (!merchant?.currentUser?.email) {
        return null;
      }
      const LDProvider = await asyncWithLDProvider({
        clientSideID,
        context: {
          kind: "user",
          key: merchant?.currentUser?.email || "anonymous",
          anonymous: !merchant?.currentUser?.email,
        },
        options: { logger: console },
      });
      return LDProvider;
    },
    {
      onError: (error) => {
        console.error("Error initializing LaunchDarkly:", error);
      },
    },
  );
};
