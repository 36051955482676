import { type ReactNode, createContext } from "react";
import { useInitializeLaunchDarkly } from "repositories/launch-darkly/useLaunchDarkly";

const LaunchDarklyContext = createContext<{
  LDProvider: React.FC | null;
}>({
  LDProvider: null,
});

interface LaunchDarklyProviderProps {
  children: ReactNode;
}

export const LaunchDarklyProvider = ({
  children,
}: LaunchDarklyProviderProps) => {
  const { data: LDProvider, error } = useInitializeLaunchDarkly();

  if (error) {
    return (
      <div>Error initializing LaunchDarkly: {(error as Error).message}</div>
    );
  }

  return (
    <LaunchDarklyContext.Provider value={{ LDProvider }}>
      {LDProvider ? <LDProvider>{children}</LDProvider> : children}
    </LaunchDarklyContext.Provider>
  );
};
