import { defaultCountries } from "react-international-phone";

// Defining the list of ISO2 codes of countries to be excluded
const excludedCountries = [
  "ae",
  "aq",
  "as",
  "ax",
  "az",
  "bd",
  "bl",
  "bq",
  "bv",
  "cc",
  "cf",
  "ck",
  "cu",
  "cx",
  "eh",
  "er",
  "fk",
  "fm",
  "fo",
  "gf",
  "gg",
  "gl",
  "gp",
  "gs",
  "hm",
  "im",
  "io",
  "ir",
  "je",
  "kg",
  "ki",
  "kp",
  "lk",
  "mf",
  "mh",
  "mm",
  "mp",
  "mq",
  "ms",
  "nf",
  "ng",
  "nr",
  "nu",
  "pf",
  "pk",
  "pm",
  "pn",
  "pw",
  "re",
  "sb",
  "sd",
  "sh",
  "sj",
  "st",
  "sy",
  "tf",
  "tj",
  "tk",
  "tl",
  "tv",
  "um",
  "uz",
  "vi",
  "wf",
  "yt",
  "ae",
];

// Filter out excluded countries from the defaultCountries array
const filteredCountries = defaultCountries.filter(
  (country) => !excludedCountries.includes(country[1]),
);
filteredCountries.push(["United Arab Emirates", "ae", "971", ".. ... ...."]);

// Sort the filteredd countries alphabetcially by country name
filteredCountries.sort((a, b) => a[0].localeCompare(b[0]));

export { filteredCountries };
