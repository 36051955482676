import { Provider } from "react-redux";

import { ReactQueryProvider } from "components/Providers";
import { DashboardNavigationContainer } from "./DashboardNavigationContainer";

import { LaunchDarklyProvider } from "providers/LaunchDarklyProvider";
import { store } from "store";

export const DashboardNavigationRoot = () => {
  return (
    <ReactQueryProvider>
      <Provider store={store}>
        <LaunchDarklyProvider>
          <DashboardNavigationContainer />
        </LaunchDarklyProvider>
      </Provider>
    </ReactQueryProvider>
  );
};
