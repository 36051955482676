import Drawer from "@mui/material/Drawer";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

// COMPONENTS
import { IconButton } from "design_system/IconButton";
import { KeyboardDoubleArrowLeft } from "design_system/Icons";
import { NavigationRailSecondaryItem } from "../NavigationRail/NavigationRailSecondaryItem";

// HOOKS
import { useAppSelector } from "store";

// OTHERS
import { colorsV2 } from "constants/colors";
import { useFlags } from "launchdarkly-react-client-sdk";
import { setActivePrimaryItem } from "../core/reducer/dashboardNavigationSlice";

export const NavigationDrawer = () => {
  const { activePrimaryItem } = useAppSelector(
    (state) => state.dashboardNavigation,
  );
  const dispatch = useDispatch();
  const flags = useFlags();

  const filteredItems = useMemo(() => {
    return activePrimaryItem.children.filter((item) => {
      if (item.id === "policies" && !flags.outOfPolicy) {
        return false;
      }
      return true;
    });
  }, [activePrimaryItem.children, flags.outOfPolicy]);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    dispatch(setActivePrimaryItem(null));
  };

  useEffect(() => {
    setOpenDrawer(true);
  }, []);

  return (
    <Drawer
      open={openDrawer}
      variant="persistent"
      anchor="left"
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: colorsV2.surface[2],
          borderRight: "unset",
          height: "calc(100% - 6.4rem)",
          inset: "unset",
          marginLeft: "8rem",
          padding: "0rem 1.2rem",
          width: "21.6rem",
          zIndex: 1002,
        },
      }}
    >
      <header className="flex h-40 items-center justify-end">
        <IconButton
          Icon={KeyboardDoubleArrowLeft}
          fill={colorsV2.text[3]}
          onClick={handleCloseDrawer}
        />
      </header>

      <div className="flex flex-col gap-8">
        {filteredItems.map((subItem) => {
          return (
            <React.Fragment key={subItem.id}>
              {subItem.hasPermission && (
                <NavigationRailSecondaryItem
                  active={location.pathname.startsWith(subItem.url)}
                  Icon={subItem.Icon}
                  label={subItem.label}
                  url={subItem.url}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </Drawer>
  );
};
